<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="6" class="mb-4 mt-3">
                <h1>Bölgeler</h1>
            </b-col>
        </b-row>
        <b-col class="mt-2">
            <b-row v-if="bolgeler.length > 0">
                <b-table responsive striped hover :fields="fields" :items="bolgeler">
                    <template v-slot:cell(detay)="detay">
                        <b-badge :to="'/bolgeForm/' + detay.item.bolge_id" class="mr-1" size="sm" variant="primary"><b-icon-search></b-icon-search></b-badge>
                        <b-badge class="hover" @click="sil(detay.item.bolge_id)" size="sm" variant="danger"><b-icon-trash></b-icon-trash></b-badge>
                    </template>
                </b-table>
            </b-row>
            <b-row v-else>
                <p>Sistemde kayıtlı bölge bulunamadı!</p>
            </b-row>
        </b-col>


        <b-button variant="primary" class="ekleButton" to="/bolgeForm">Yeni Ekle</b-button>
    </b-container>
</template>

<script>
import Swal from 'sweetalert2';

export default{
    data(){
        return {
            fields : [
                {
                    "key" : "bolge_id",
                    "label" : "ID",
                    "sortable": "true",
                    "thStyle": { width: '70px'},
                },
                {
                    "key" : "bolge_ad",
                    "label" : "Bölge Ad",
                    "sortable": "true"
                },
                {
                    "key" : "user_adsoyad",
                    "label" : "Bölge Belediye",
                    "sortable": "true"
                },
                {
                    "key" : "detay",
                    "label" : "Durum",
                    "thStyle": { width: '30px'},
                },
            ],
        }
    },
    
    computed: {
        bolgeler(){
            return this.$store.state.bolgeler;
        },
    },

    mounted(){
        this.$store.dispatch("usersMusait");
    },

    methods: {
        sil(a){
            Swal.fire({
                title: 'Emin Misin?',
                text: "Bölge bilgisi sistemden kalıcı olarak silinecektir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet',
                cancelButtonText: 'Hayır',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("bolgeSil", a);
                }
            })
        }
    }
}
</script>


<style scoped>
.ekleButton{
    position: fixed;
    right: 15px;
    bottom: 15px;
}
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}
.hover:hover{
    cursor: pointer;
}
</style>